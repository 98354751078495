.services {
  padding: 60px 20px;
  background-color: black;
  text-align: center;
  color: white;
}

.services h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-item {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  color: white;
}

.service-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.service-item p {
  font-size: 1em;
}

@media (max-width: 768px) {
  .service-item {
    width: 100%;
  }
}
