.gym-highlight {
    padding: 50px 20px;
    text-align: center;
    background-color: black; /* Background color */
    color: white; /* Text color */
  }
  
  .gym-highlight h2 {
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: bold;
    color: white; /* Text color */
  }
  
  .gym-images {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .gym-images img {
    width: 45%;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .gym-images {
      flex-direction: column;
    }
  
    .gym-images img {
      width: 100%;
    }
  }
  