.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: black;
  color: white;
  position: relative;
}

.navbar-brand {
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-nav {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  z-index: 999; /* Ensure dropdown appears above other content */
}

.navbar-nav-open {
  display: flex !important;
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.nav-item a:hover {
  text-decoration: underline;
}

.navbar-toggler {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 20px 0;
  }

  .navbar-toggler {
    display: block;
  }

  .nav-item {
    margin: 10px 0;
  }
}
