.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  color: white;
  text-align: center;
  background-color: black;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  z-index: 1; /* This ensures the content is above the video */
  max-width: 600px;
  padding: 20px;
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.cta-button {
  padding: 10px 20px;
  font-size: 2em;
  font-weight: 600;
  color: black;
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: gray;
}

.hero-credit {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  color: white;
  z-index: 1; /* Ensures the credit text is above the video */
}

.hero-credit a {
  color: white;
  text-decoration: none;
}

.hero-credit a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5em;
  }

  .hero p {
    font-size: 1em;
  }

  .cta-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .hero-credit {
    font-size: 0.8em;
  }
}
