.contact {
  padding: 50px 20px;
  text-align: center;
  background-color: black; /* Background color */
  color: white; /* Text color */
}

.contact h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
  color: white; /* Text color */
}

.contact p {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: white; /* Text color */
}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  color: black; /* Button text color */
  background-color: white; /* Button color */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-button:hover {
  background-color: #f1f1f1;
  color: black; /* Button text color on hover */
}

@media (max-width: 768px) {
  .contact h2 {
    font-size: 2em;
  }

  .contact p {
    font-size: 1em;
  }

  .contact-button {
    font-size: 1em;
  }
}
