body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: white;
  background-color: black;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
