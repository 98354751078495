.testimonials {
  padding: 60px 20px;
  background-color: black;
  text-align: center;
  color: white;
}

.testimonials h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.testimonial-item {
  margin-bottom: 20px;
}

.testimonial-item p {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .testimonials h2 {
    font-size: 2em;
  }

  .testimonial-item p {
    font-size: 1em;
  }
}
