.about {
  padding: 50px 20px;
  text-align: center;
  background-color: black;
  color: white;
}

.about h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
  color: #f8f8f8;
}

.about p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #d3d3d3;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.media-item {
  position: relative;
}

.media-item img,
.media-item video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.featured-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 40px;
  justify-content: center;
}

.featured-item {
  position: relative;
  text-align: center;
  flex: 1 1 30%;
  max-width: 30%;
}

.featured-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #f8f8f8;
}

.featured-item img,
.featured-item video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 2em;
  }

  .about p {
    font-size: 1em;
  }

  .media-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .featured-grid {
    flex-direction: column;
  }

  .featured-title {
    font-size: 1.2em;
  }

  .featured-item {
    max-width: 100%;
  }
}
